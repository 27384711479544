<mat-card>
  <div class="fx_space-between-baseline">
    <mat-card-title>{{ 'scp.common.devices' | translate }}</mat-card-title>

    <div class="fx_gap_15">
      <span [innerHTML]="'scp.devices.devices_count' | translate : { value: paginator?.length || 0 }"> </span>
      <span [innerHTML]="'scp.devices.active_licenses_count' | translate : { value: scpLicenseInfo?.assigned || 0 }"> </span>
      <span [innerHTML]="'scp.devices.inactive_licenses_count' | translate : { value: scpLicenseInfo?.unassigned || 0 }"> </span>
      <span [innerHTML]="'scp.devices.total_licenses_count' | translate : { value: scpLicenseInfo?.totalLicenses || 0 }"> </span>
    </div>
  </div>

  <mat-card-actions class="mb-0 fx_space-between-end">
    <span class="fx_rowwarp fx_startcenter">
      <button
        class="large"
        mat-icon-button
        color="primary"
        matTooltip="{{ 'scp.devices.add_device_button_text' | translate }}"
        (click)="showUpsertDeviceDialog()"
      >
        <mat-icon>add_circle</mat-icon>
      </button>

      <button mat-flat-button color="primary" class="mx-3" (click)="updateActivationStatus(true)" [disabled]="isSubmitting || shouldDisableActivateButton">
        {{ 'scp.devices.activate_device_button_text' | translate }}
      </button>

      <button mat-flat-button color="primary" class="mx-0" (click)="updateActivationStatus(false)" [disabled]="isSubmitting || shouldDisableDeactivateButton">
        {{ 'scp.devices.deactivate_device_button_text' | translate }}
      </button>

      <button mat-flat-button color="primary" class="mx-3" (click)="showMfpSearchDialog()">{{ 'scp.devices.start_mfp_search' | translate }}</button>

      <span class="mx-3 fx_startend fx_gap_15">
        <mat-form-field>
          <mat-label> {{ 'scp.devices.filter_view_text' | translate }} </mat-label>
          <mat-select [formControl]="statusFilterFormCtrl">
            <mat-option value="all"> {{ 'scp.devices.filter_view_option_all' | translate }} </mat-option>
            <mat-option [value]="true"> {{ 'scp.devices.filter_view_option_licensed_devices' | translate }} </mat-option>
            <mat-option [value]="false"> {{ 'scp.devices.filter_view_option_unlicensed_devices' | translate }} </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label> {{ 'scp.devices.filter_devices_search_text' | translate }} </mat-label>
          <input matInput [formControl]="searchFilterFormCtrl" />
        </mat-form-field>
      </span>
    </span>

    <button mat-icon-button matTooltip="{{ 'scp.common.refresh_data_button_text' | translate }}" (click)="getDevicesData()">
      <mat-icon>refresh</mat-icon>
    </button>
  </mat-card-actions>

  <mat-card-content>
    <div class="table-responsive mat-elevation-z8">
      <table mat-table matSort matSortActive="ip" matSortDirection="asc" [dataSource]="dataSource" class="w-100">
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              (change)="toggleAllRows()"
              [checked]="selection.hasValue() && isAllRowsSelected()"
              [indeterminate]="selection.hasValue() && !isAllRowsSelected()"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (change)="selection.toggle(row.guid)" [checked]="selection.isSelected(row.guid)"> </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="activated">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'scp.devices.columns.status' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ (row.activated ? 'scp.common.licensed' : 'scp.common.unlicensed') | translate }}</td>
        </ng-container>

        <ng-container matColumnDef="typeName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'scp.devices.columns.type' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.type?.name == 'OSA' ? 'OSA' : 'ESF' }}</td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'scp.devices.columns.alias' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.name }}</td>
        </ng-container>

        <ng-container matColumnDef="model">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'scp.devices.columns.model' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.model }}</td>
        </ng-container>

        <ng-container matColumnDef="ip">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'scp.devices.columns.ip' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.ip }}</td>
        </ng-container>

        <ng-container matColumnDef="serial">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'scp.devices.columns.serial' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.serial }}</td>
        </ng-container>

        <ng-container matColumnDef="location">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'scp.devices.columns.location' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.location }}</td>
        </ng-container>

        <ng-container matColumnDef="directPrintEnabled">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'scp.devices.columns.directPrintEnabled' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ (row.directPrintEnabled ? 'scp.common.enabled' : 'scp.common.disabled') | translate }}</td>
        </ng-container>

        <ng-container matColumnDef="actions" stickyEnd>
          <th mat-header-cell *matHeaderCellDef>{{ 'scp.devices.columns.actions' | translate }}</th>
          <td mat-cell *matCellDef="let row">
            <button mat-icon-button [matMenuTriggerFor]="rowMenu">
              <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #rowMenu="matMenu">
              <button mat-menu-item (click)="showUpsertDeviceDialog(row)">{{ 'scp.common.edit_button_text' | translate }}</button>
              <button mat-menu-item (click)="deleteDeviceDialog(row)">{{ 'scp.common.delete_button_text' | translate }}</button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons aria-label="Select page"> </mat-paginator>
    </div>
  </mat-card-content>
</mat-card>

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';
import { UserService } from '../../shared/services';
import { stackUrls } from '../../layout/stackUrls';

@Component({
    selector: 'app-baza.dialog',
    templateUrl: '../eulaDialog/eula.de.dialog.html',
    styleUrls: ['../eulaDialog/eula.dialog.scss'],
})
export class EulaDialogEUDEComponent implements OnInit {
    public privacyUrl;
    public termsUseUrl;
    public supportType;
    public guest = false;
    public showSCPToU: boolean = false;
    constructor(
        public eulaDialogRef: MatDialogRef<EulaDialogEUDEComponent>,
        public authService: AuthService,
        public userService: UserService,
        @Inject(MAT_DIALOG_DATA) public data: any) {
            this.guest = this.data.guest;
            this.showSCPToU = this.data.showSCPToU;
        }
    ngOnInit() {
        this.eulaDialogRef.disableClose = true;
        const browserLang = this.authService.getBrowserLanguage();
        const stackUrl = stackUrls(browserLang)
        this.privacyUrl = stackUrl.privacyUrl;
        this.termsUseUrl = stackUrl.termsUseUrl;
        this.supportType = stackUrl.supportType;
     }

    submit() { }

    onCancel(): void {
        this.eulaDialogRef.close(0);
        this.authService.logout();
    }

    agreeClick() {
        // On confirmation from user return dialog close : 1
        this.userService.eulaTermsAgreed(true, this.guest);
        this.eulaDialogRef.close(1);
    }
}

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Inject, Component, OnInit } from '@angular/core';
@Component({
    selector: 'app-dialog-provider',
    templateUrl: './dialogprovider.component.html',
    styleUrls: ['./dialogprovider.component.scss']
  })
  export class DialogProviderComponent implements OnInit {
    public tenantData: any;
    public redirectURI: any;
    ngOnInit(): void {
        this.tenantData = this.data.tenantData;
    }

    constructor(
      public dialogRef: MatDialogRef<DialogProviderComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      ) {}

    onNoClick(): void {
        this.redirectURI = window.location.protocol + '//' + window.location.hostname;
        if (this.tenantData.provider === 'waad'){
            this.redirectURI = this.redirectURI;
        }
        window.location.replace(this.redirectURI);
    }

    onYesClick(): void {
        this.dialogRef.close();
    }

  }

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'displayUser',
})
export class DisplayUserPipe implements PipeTransform {

    constructor() {
    }

    transform(value: any, args?: any): string {
        return (args === 'title') ? this.getTitle(value) : this.getDisplayOption(value);
    }

    getTitle(user): string {
        const title = `${user.displayName} | ${user.emailId}`;
        return title;
    }

    getDisplayOption(user): string {
        const email = `${user.emailId}`;
        return email;
    }

}

/*
 * Public API Surface of auth-n
 */

export * from './lib/auth-n.service';
export * from './lib/auth-n.component';
export * from './lib/auth-n.module';
// export * from './lib/lib-auth.service';

export * from './lib/Services/auth-env';
export * from './lib/synappx-auth.service';
export * from './lib/Services/synappx-env.service';
export * from './lib/admin-auth.service';

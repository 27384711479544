// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.center-align {
  text-align: center;
  padding: 0;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNvbmZpcm0tYWN0aW9uLWRpYWxvZy5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFQTtFQUNJLGtCQUFBO0VBQ0EsVUFBQTtBQURKIiwiZmlsZSI6ImNvbmZpcm0tYWN0aW9uLWRpYWxvZy5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIkBpbXBvcnQgJy9zcmMvc3R5bGVzL2NvbG9ycy5zY3NzJztcblxuLmNlbnRlci1hbGlnbiB7XG4gICAgdGV4dC1hbGlnbjogY2VudGVyO1xuICAgIHBhZGRpbmc6IDA7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/shared/dialogs/confirm-action-dialog/confirm-action-dialog.component.scss"],"names":[],"mappings":"AAEA;EACI,kBAAA;EACA,UAAA;AADJ;AACA,obAAob","sourcesContent":["@import '/src/styles/colors.scss';\n\n.center-align {\n    text-align: center;\n    padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

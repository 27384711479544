// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-simple-snackbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  line-height: 20px;
  opacity: 1;
}

button {
  outline: none;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm5vdGlmaWNhdGlvbi1tZXNzYWdlcy1jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBRTtFQUNFLGFBQUE7RUFDQSw4QkFBQTtFQUNBLG1CQUFBO0VBQ0EsWUFBQTtFQUNBLGlCQUFBO0VBQ0EsVUFBQTtBQUNKOztBQUNFO0VBRUUsYUFBQTtBQUNKIiwiZmlsZSI6Im5vdGlmaWNhdGlvbi1tZXNzYWdlcy1jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIiAgLm1hdC1zaW1wbGUtc25hY2tiYXIge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAganVzdGlmeS1jb250ZW50OiBzcGFjZS1iZXR3ZWVuO1xuICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gICAgaGVpZ2h0OiAxMDAlO1xuICAgIGxpbmUtaGVpZ2h0OiAyMHB4O1xuICAgIG9wYWNpdHk6IDE7XG4gIH1cbiAgYnV0dG9uIHtcbiAgICAvLyBSZW1vdmUgZm9jdXMgYXJvdW5kIGJ1dHRvblxuICAgIG91dGxpbmU6IG5vbmU7XG4gIH1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/shared/components/notifications/notification-messages-component.scss"],"names":[],"mappings":"AAAE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,YAAA;EACA,iBAAA;EACA,UAAA;AACJ;;AACE;EAEE,aAAA;AACJ;AACA,4sBAA4sB","sourcesContent":["  .mat-simple-snackbar {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    height: 100%;\n    line-height: 20px;\n    opacity: 1;\n  }\n  button {\n    // Remove focus around button\n    outline: none;\n  }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Pipe({
    name: 'displayWorkspace',
})
export class DisplayWorkspacePipe implements PipeTransform {

    private isGoogle;

    constructor(private authService: AuthService) {
        this.isGoogle = this.authService.applicationType === 'google-apps';
    }

    transform(value: any, args?: any, isGroup?: any): string {
        return (args === 'title') ? this.getTitle(value, this.isGoogle, isGroup) : this.getDisplayOption(value, this.isGoogle, isGroup);
    }

    getTitle(room, isGoogle, isGroup): string {
        const googleTitle = (!isGroup) ? `${room.displayName} | ${room.place} | ${room.emailId}` : `${room.displayName}`;
        const office365Title = `${room.displayName} | ${room.emailId}`;
        return (isGoogle) ? googleTitle : office365Title;
    }

    getDisplayOption(room, isGoogle, isGroup): string {
        const googleDisplay = (!isGroup) ? `${room.place} | ${room.emailId}` : '';
        const office365Title = `${room.emailId}`;
        return (isGoogle) ? googleDisplay : office365Title;
    }

}

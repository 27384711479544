// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  display: flex;
  flex-direction: column;
  min-width: 450px;
}

.container > * {
  width: 100%;
}

.form {
  display: flex;
  padding-top: 6px;
}

.mat-form-field {
  font-size: 16px;
  flex-grow: 1;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNvbmZpcm0tb3BlcmF0aW9uLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVBO0VBQ0ksYUFBQTtFQUNBLHNCQUFBO0VBQ0EsZ0JBQUE7QUFESjs7QUFJQTtFQUNJLFdBQUE7QUFESjs7QUFJQTtFQUNJLGFBQUE7RUFDQSxnQkFBQTtBQURKOztBQUlBO0VBQ0ksZUFBQTtFQUNBLFlBQUE7QUFESiIsImZpbGUiOiJjb25maXJtLW9wZXJhdGlvbi5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIkBpbXBvcnQgJy9zcmMvc3R5bGVzL2NvbG9ycy5zY3NzJztcblxuLmNvbnRhaW5lciB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICAgIG1pbi13aWR0aDogNDUwcHg7XG59XG5cbi5jb250YWluZXI+KiB7XG4gICAgd2lkdGg6IDEwMCU7XG59XG5cbi5mb3JtIHtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIHBhZGRpbmctdG9wOiA2cHg7XG59XG5cbi5tYXQtZm9ybS1maWVsZCB7XG4gICAgZm9udC1zaXplOiAxNnB4O1xuICAgIGZsZXgtZ3JvdzogMTtcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/layout/components/dialogs/confirm-operation/confirm-operation.component.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,sBAAA;EACA,gBAAA;AADJ;;AAIA;EACI,WAAA;AADJ;;AAIA;EACI,aAAA;EACA,gBAAA;AADJ;;AAIA;EACI,eAAA;EACA,YAAA;AADJ;AACA,oyBAAoyB","sourcesContent":["@import '/src/styles/colors.scss';\n\n.container {\n    display: flex;\n    flex-direction: column;\n    min-width: 450px;\n}\n\n.container>* {\n    width: 100%;\n}\n\n.form {\n    display: flex;\n    padding-top: 6px;\n}\n\n.mat-form-field {\n    font-size: 16px;\n    flex-grow: 1;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../../../../../../src/app/shared/services/auth.service';
import { environment } from '../../../../../../src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-grant-permission-login',
  templateUrl: './grant-permission-login.component.html',
  styleUrls: ['./grant-permission-login.component.scss']
})
export class GrantPermissionLoginComponent implements OnInit {

    public tenantDomain: string;
    public azureAppId: string;
    public callbackUrl: string;
    public requiredScopes: string;
    public url: string;
    public userEmail: string;
    public PermissionsRequiredMessage: string;

    constructor(
        public confirmDialogRef: MatDialogRef<GrantPermissionLoginComponent>,
        private authService: AuthService,
        private translate: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        }

        ngOnInit() {
            this.userEmail = this.authService.userUpn;
            this.tenantDomain = this.userEmail.toLowerCase().split('@')[1];
            this.azureAppId = environment.AuthConfig.azure_app_client_id;
            this.callbackUrl = encodeURIComponent(environment.AuthConfig.callbackURL);
            // include User.ReadBasic.All scope to Eliminate Handoff to MS Global Admin SHPLT-6143
            this.requiredScopes = 'openid profile email offline_access User.Read User.ReadBasic.All Group.Read.All Directory.Read.All';
            if (this.authService.viewGo || this.authService.viewMeeting) {
                this.requiredScopes += " User.Read.All";
            }
            this.url = `https://login.microsoftonline.com/${this.tenantDomain}/v2.0/adminconsent?client_id=${this.azureAppId}&redirect_uri=${this.callbackUrl}&scope=${this.requiredScopes}`;
            this.translate.get('PermissionsRequiredMessage', {
                ahref_start: `<a href='${this.url}'><u>`,
                ahref_end: `</u></a>`,
            }).subscribe( (PermissionsRequiredMessage: string) => {
            this.PermissionsRequiredMessage = PermissionsRequiredMessage;
        });
        }

}


import { InjectionToken } from '@angular/core';

/**
 * This is the configuration from the Auth0 vendor (www.auth0.com)
 */
export class Auth0Config {
    /**
     * The Auth0 Application client ID
     * Refer to Auth0 Applications section in *.auth0.com
     */
    clientID?: string;

    /**
     * The domain of the auth0 account
     * Refer to Auth0 Applications section in *.auth0.com
     */
    domain?: string;

    /**
     * The callbackURL to redirect after successful authentication
     * Refer to Auth0 Applications section in *.auth0.com
     */
    callbackURL?: string;

    /**
     * The Auth0 API identifier. This is usually in URL format.
     * Refer to Auth0 API section in *.auth0.com
     */
    apiUrl?: string;

    /**
     * The Auth0 API identifier. This is usually in URL format.
     * Refer to Auth0 API section in *.auth0.com
     */
    // SSH EKM
    partnerApiUrl?: string;
}

export class AuthEnv {
    production: boolean = false;

    /**
     * The auth0 configuration
     */
    public AuthConfig: Auth0Config;

    // /**
    //  * The client id of the registered application in the Azure account.
    //  * This is strictly not in Auth0
    //  */
    // azure_app_client_id?: String;

    public googleScopes: Array<string>;

    /**
     * The base url for SSP Common API
     */
    public sspCommonApiBaseUrl: string;

    /**
     * The url for SSP Common Tenant API
     */
    public sspCommonTenantUrl: string;

    /**
     * The region where the current stack is deployed. Valid values: (US, EU)
     */
    public stackRegion: string;

    /**
     * The logout URL For sharp start service
     */
    public sharpstart_logout_url: string;
//     sharpstart_logout_url: 'https://startrmm.sharpusa.net/DesktopModules/Inventua%20-%20Custom%20Login%20Services/1.0/Oauth2Logout.ashx'
//
 }

/**
 * Define an InjectionToken for AuthEnv. This is requried for injecting runtime data into a service.
 * Refer: https://angular.io/guide/dependency-injection-providers#using-an-injectiontoken-object
 */
export const AUTH_ENV = new InjectionToken<AuthEnv>('AUTH_ENV');


// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  display: flex;
  flex-direction: column;
  min-width: 450px;
}

.container > * {
  width: 100%;
}

.form {
  display: flex;
  padding-top: 6px;
}

.mat-form-field {
  font-size: 16px;
  flex-grow: 1;
}

.disabled label {
  color: #ccc;
}

span.inner_label {
  padding-left: 10px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNvbmZpcm0tZGVsZXRlLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVBO0VBQ0ksYUFBQTtFQUNBLHNCQUFBO0VBQ0EsZ0JBQUE7QUFESjs7QUFJQTtFQUNJLFdBQUE7QUFESjs7QUFJQTtFQUNJLGFBQUE7RUFDQSxnQkFBQTtBQURKOztBQUlBO0VBQ0ksZUFBQTtFQUNBLFlBQUE7QUFESjs7QUFJQTtFQUNJLFdBQUE7QUFESjs7QUFJQTtFQUNJLGtCQUFBO0FBREoiLCJmaWxlIjoiY29uZmlybS1kZWxldGUuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyJAaW1wb3J0ICcvc3JjL3N0eWxlcy9jb2xvcnMuc2Nzcyc7XG5cbi5jb250YWluZXIge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgICBtaW4td2lkdGg6IDQ1MHB4O1xufVxuXG4uY29udGFpbmVyPioge1xuICAgIHdpZHRoOiAxMDAlO1xufVxuXG4uZm9ybSB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBwYWRkaW5nLXRvcDogNnB4O1xufVxuXG4ubWF0LWZvcm0tZmllbGQge1xuICAgIGZvbnQtc2l6ZTogMTZweDtcbiAgICBmbGV4LWdyb3c6IDE7XG59XG5cbi5kaXNhYmxlZCBsYWJlbCB7XG4gICAgY29sb3I6ICNjY2M7XG59XG5cbnNwYW4uaW5uZXJfbGFiZWwge1xuICAgIHBhZGRpbmctbGVmdDogMTBweDtcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/layout/components/dialogs/confirm-delete/confirm-delete.component.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,sBAAA;EACA,gBAAA;AADJ;;AAIA;EACI,WAAA;AADJ;;AAIA;EACI,aAAA;EACA,gBAAA;AADJ;;AAIA;EACI,eAAA;EACA,YAAA;AADJ;;AAIA;EACI,WAAA;AADJ;;AAIA;EACI,kBAAA;AADJ;AACA,g9BAAg9B","sourcesContent":["@import '/src/styles/colors.scss';\n\n.container {\n    display: flex;\n    flex-direction: column;\n    min-width: 450px;\n}\n\n.container>* {\n    width: 100%;\n}\n\n.form {\n    display: flex;\n    padding-top: 6px;\n}\n\n.mat-form-field {\n    font-size: 16px;\n    flex-grow: 1;\n}\n\n.disabled label {\n    color: #ccc;\n}\n\nspan.inner_label {\n    padding-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

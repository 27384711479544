// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const apiGateWay = {
    rmm: 'https://dev5-cloudrmm-api.sharpb2bcloud.com/rmm/',
    rmmPDM: 'https://dev5-cloudrmm-api.sharpb2bcloud.com/dd/',
    rmmAgent: 'https://dev5-cloudrmm-api.sharpb2bcloud.com/agent/',
    rmmIdp: 'https://dev5-cloudrmm-api.sharpb2bcloud.com/idp/',
    rmmTco: 'https://dev5-cloudrmm-api.sharpb2bcloud.com/rmmx/',
    rmmFss: 'https://dev5-cloudrmm-api.sharpb2bcloud.com/rmm/fss/',
    rmmStateful: 'https://dev5-cloudrmm-api.sharpb2bcloud.com/stateful/'
}
export const environment = {
    production: false,
    groupId: "a87e170c-8ae5-4c4e-840a-719770792b61",
    stackRegion: 'US',
    rmmFedrationOption: {
        CLINT_ID_MSA: "d6dd773a-d02a-498d-a9b6-ac0117ed2963",
        CLINT_ID_GOOGLE: "d91dcbae-bb43-40d3-9168-ec8e2063f4b0",
        REDIRECT_URI: "https://rmm-sharp.net/callback",
        MSA_URI: "https://login.microsoftonline.com/common/oauth2/v2.0/token",
        GOOGLE_URI: "https://rmmapp01.b2clogin.com/rmmapp01.onmicrosoft.com/oauth2/v2.0/token?p=b2c_1a_signup_signin",
        LOGOUT_MSA: "https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=https://rmm-sharp.net",
        LOGOUT_GOOGLE: "https://rmmapp01.b2clogin.com/rmmapp01.onmicrosoft.com/b2c_1a_signup_signin/oauth2/v2.0/logout?post_logout_redirect_uri=https://rmm-sharp.net",
        LOGOUT_MSA_REDIRECT: "https://rmm-sharp.net"
    },
    rmmHeader: {
        Ocp_Apim_Subscription_Key: '22e8a3f63d4a4fbe9daac4e4b5938768'
    },
    rmmHeaderAgent: {
        Ocp_Apim_Subscription_Key: '22e8a3f63d4a4fbe9daac4e4b5938768',
    },

    rmmSNMPGetDeviceList: {
        url: apiGateWay.rmm + 'device/getDeviceList',
        getDeviceDetails: apiGateWay.rmm + 'device/getDeviceDetail'
    },
    rmmFilterOperations: {
        getFilter: apiGateWay.rmm + 'filter/getFilter',
        createFilter: apiGateWay.rmm + 'filter/createFilter',
        setFilter: apiGateWay.rmm + 'filter/setFilter',
        deleteFilter: apiGateWay.rmm + 'filter/deleteFilter',
    },
    rmmDeviceOperations: {
        getDeviceCount: apiGateWay.rmm + 'device/getDeviceCount',
        startStatusUpdate: apiGateWay.rmm + 'device/startStatusUpdate',
        startRegisterDevice: apiGateWay.rmm + 'device/startRegisterDevice',
        startRemoveDevice: apiGateWay.rmm + 'device/startRemoveDevice',
        startPasteDevice: apiGateWay.rmm + 'device/startPasteDevice',
        getRemoteOperationPanelURL: apiGateWay.rmm + 'device/remoteOperationPanelUrl',
        getErrorCount: apiGateWay.rmm + 'device/status/count',
        getErrorList: apiGateWay.rmm + 'device/status/list',
        sendDashboardStatus: apiGateWay.rmm + 'device/dashboard/conf'
    },

    rmmDashboardOperations: {
        getDailyMonthlyWeeklyCounterUsage: apiGateWay.rmm + 'dashboard/counter/usage',
        getCounterUsageByDevice: apiGateWay.rmm + 'dashboard/device/counter/usage',

    },

    rmmGroupOperations: {
        createGroup: apiGateWay.rmm + 'group/createGroup',
        getGroupList: apiGateWay.rmm + 'group/getGroupList',
        getGroupTree: apiGateWay.rmm + 'group/getGroupTree',
        getMailAlertList: apiGateWay.rmm + 'group/getMailAlertList',
        setMailAlertList: apiGateWay.rmm + 'group/setMailAlertList',
        getOperationResult: apiGateWay.rmm + 'group/getOperationResult',
        editGroup: apiGateWay.rmm + 'group/editGroup',
        startRemoveGroup: apiGateWay.rmm + 'group/startRemoveGroup',
        groupInfo: apiGateWay.rmm + 'group/groupInfo',
        getSpecialCounter: apiGateWay.rmm + 'group/counter/conf',
        setSpecialCounter: apiGateWay.rmm + 'group/counter/conf',
        sendTestMail: apiGateWay.rmm + 'group/email/test',
        deleteEmailSettingsMail: apiGateWay.rmm + 'group/emailSettings/del'
    },
    rmmDBOperations: {
        checkProgress: apiGateWay.rmm + 'db/checkProgress'
    },
    rmmFssOperations: {
        checkProgress: apiGateWay.rmmStateful + 'fss/export/checkProgress'
    },
    rmmFssExecutionProgess: {
        executionProgress: apiGateWay.rmmStateful + 'fss/service/executionProgress'
    },
    rmmAgentOperations: {
        createAgent: apiGateWay.rmmAgent + 'createAgent',
        editAgent: apiGateWay.rmm + 'agent/editAgent',
        deleteAgent: apiGateWay.rmmAgent + 'deleteAgent',
        getAgent: apiGateWay.rmm + 'agent/getAgent',
        getAgentList: apiGateWay.rmmAgent + 'getAgentList',
        getAgentIds: apiGateWay.rmm + 'agent/getAgentIds',
        getSNMPv1Credential: apiGateWay.rmm + 'agent/getSNMPV1Credential',
        setSNMPv1Credential: apiGateWay.rmm + 'agent/setSNMPV1Credential',
        getSNMPv1ConditionList: apiGateWay.rmm + 'agent/getSNMPv1ConditionList',
        deleteSNMPV1Credential: apiGateWay.rmm + 'agent/deleteSNMPV1Credential',
        getSNMPv3ConditionList: apiGateWay.rmm + 'agent/getSNMPv3ConditionList',
        getSNMPV3Credential: apiGateWay.rmm + 'agent/getSNMPV3Credential',
        setSNMPV3Credential: apiGateWay.rmm + 'agent/setSNMPV3Credential',
        deleteSNMPV3Credential: apiGateWay.rmm + 'agent/deleteSNMPV3Credential',
        getAutoRegisterStatus: apiGateWay.rmm + 'group/getAutoRegisterStatus',
        setAutoRegisterStatus: apiGateWay.rmm + 'group/setAutoRegisterStatus',
        startDiscovery: apiGateWay.rmm + 'device/startDiscovery',
        getSNMPConditionList: apiGateWay.rmm + 'agent/snmp/condition',
        createSNMPCredential: apiGateWay.rmm + 'agent/snmp/condition',
        getSNMPCredential: apiGateWay.rmm + 'agent/snmp/condition',
        setSNMPCredential: apiGateWay.rmm + 'agent/snmp/condition',
        deleteSNMPCredential: apiGateWay.rmm + 'agent/snmp/del',
        getDiscoverySettings: apiGateWay.rmm + 'agent/discover/condition',
        setDiscoverySettings: apiGateWay.rmm + 'agent/discover/condition',
        getDiscoveryCondition: apiGateWay.rmm + 'agent/discover/conf',
        setDiscoveryCondition: apiGateWay.rmm + 'agent/discover/conf',
        createDiscoveryCondition: apiGateWay.rmm + 'agent/discover/conf',
        deleteDiscoverySettings: apiGateWay.rmm + 'agent/discovery/condition/del',
        setScheduleSettings: apiGateWay.rmm + 'group/schedule',
        getScheduleSettings: apiGateWay.rmm + 'group/schedule',
        getAgentDownloader: apiGateWay.rmmAgent + 'downloadInstaller',
        addSearchTarget: apiGateWay.rmmIdp + 'agent/addSearchTarget',
        getSearchTargetList: apiGateWay.rmmIdp + 'agent/getSearchTargetList',
        deleteSearchTarget: apiGateWay.rmmIdp + 'agent/deleteSearchTarget',
        executeDeviceSearch: apiGateWay.rmmIdp + 'agent/executeDeviceSearch',
        editSearchTarget: apiGateWay.rmmIdp + 'agent/updateSearchTarget',
        startRegisterDevice: apiGateWay.rmm + 'device/registerDevice',
        registerDevice: apiGateWay.rmmIdp + 'device/registerDevice',
        updateAgentInfo: apiGateWay.rmmAgent + 'updateAgentInfo',
        agentScheduleSettings: apiGateWay.rmm + 'agent/schedule',
        updateAgentStatus: apiGateWay.rmmAgent + 'confirmAgentInstall',
        getActivationStatus: apiGateWay.rmmAgent + 'getActivationStatus',
        requestActivationCode: apiGateWay.rmmAgent + 'requestActivationCode',
        checkProgress: apiGateWay.rmmAgent + 'checkProgress',
        setExecuteMode: apiGateWay.rmmAgent + 'requestAgentUpdate',
        setUpdateMode: apiGateWay.rmmAgent + 'setUpdateMode',
        getGlobalRemoteOperationUrl: apiGateWay.rmmAgent + 'globalRemoteOperationUrl',
        connectGlobalRemoteOperation: apiGateWay.rmmAgent + 'connectGlobalRemoteOperation',
        getUpdateSuspendedList: apiGateWay.rmmAgent + 'getUpdateSuspendedList',
        generateSSHKey: apiGateWay.rmmStateful + 'agent/idp/generateSSHKey',
        checkSSHKeyProgress: apiGateWay.rmmStateful + 'agent/idp/checkSSHKeyProgress',
        getSSHKeyStatus: apiGateWay.rmmIdp + 'agent/getSSHKeyStatus',
        downloadSSHKey: apiGateWay.rmmIdp + 'agent/downloadSSHKey',
        deleteSSHKey: apiGateWay.rmmStateful + 'agent/idp/deleteSSHKey',
        getAgentLogList: apiGateWay.rmmAgent + 'getAgentLogList',
        requestLogUpload: apiGateWay.rmmStateful + 'agent/requestLogUpload',
        downloadAgentLog: apiGateWay.rmmAgent + 'downloadAgentLog',
        inactivation: apiGateWay.rmmAgent + 'inactivation',
    },
    rmmTcoOperation: {
        getTcoDeviceList: apiGateWay.rmmTco + 'tco/device',
        setSecurityLevel: apiGateWay.rmmTco + 'tco/device/securityLevel',
        getFilterSetting: apiGateWay.rmmTco + 'tco/filter/conf',
        setFilterSetting: apiGateWay.rmmTco + 'tco/filter/conf',
        getSSLSetting: apiGateWay.rmmTco + 'tco/ssl/conf',
        setSSLSetting: apiGateWay.rmmTco + 'tco/ssl/conf',
        getIPSecIKEv1Setting: apiGateWay.rmmTco + 'tco/ipsec/conf',
        setIPSecIKEv1Setting: apiGateWay.rmmTco + 'tco/ipsec/conf',
        getIPSecRuleList: apiGateWay.rmmTco + 'tco/ipsec/rules',
        deleteIPSecRuleList: apiGateWay.rmmTco + 'tco/ipsec/rules/del',
        setIPSecRule: apiGateWay.rmmTco + 'tco/ipsec/rules/conf',
        getIPSecRuleDetail: apiGateWay.rmmTco + 'tco/ipsec/rules/conf',
        createIPSecRule: apiGateWay.rmmTco + 'tco/ipsec/rules/conf',
        getPowerManagementScheduleList: apiGateWay.rmmTco + 'tco/device/power/schedule',
        getPowerManagementScheduleDetails: apiGateWay.rmmTco + 'tco/device/power/schedule',
        deletePowerManagementSchedule: apiGateWay.rmmTco + 'tco/device/power/schedule/del',
        createPowerManagementSchedule: apiGateWay.rmmTco + 'tco/device/power/schedule',
        setPowerManagementSchedule: apiGateWay.rmmTco + 'tco/device/power/schedule',
        applyPowerMngSchedule: apiGateWay.rmmTco + 'tco/device/powerapply/schedule',
        getDeviceCount: apiGateWay.rmmTco + 'tco/getDeviceCount',
        getSecurityReport: apiGateWay.rmmTco + 'tco/securityReport',
        exportSecurityReport: apiGateWay.rmmTco + 'tco/securityReport/export',
        getPowerManagementScheduleDeviceDetails: apiGateWay.rmmTco + 'tco/device/power/deviceschedule',
        getServiceReport: apiGateWay.rmmTco + 'tco/device/serviceReport',
        serviceReportDownload: apiGateWay.rmmTco + 'tco/device/serviceReport/download',
        violations: apiGateWay.rmmTco + 'tco/securityReport/violations',
        violationsExport: apiGateWay.rmmTco + 'tco/securityReport/violations/export',
        deleteCloningDevice: apiGateWay.rmmTco + 'tco/cloning/file/delete',
        saveCloningFile: apiGateWay.rmmTco + 'tco/device/download',
        getServiceReportList: apiGateWay.rmmTco + 'tco/device/serviceReport/list',
        exportServiceReport: apiGateWay.rmmTco + 'tco/device/serviceReport/export',
        getSetPasswordSetting: apiGateWay.rmmTco + 'tco/passwordRules/conf',
        getSetIntrusionsetting: apiGateWay.rmmTco + 'tco/intrusionDetection/conf',
        getSetDocumentSetting: apiGateWay.rmmTco + 'tco/secDocAdmin/conf',
        getSetSMimeSetting: apiGateWay.rmmTco + 'tco/secMIMEInfo/conf',
        getSetVirusSetting: apiGateWay.rmmTco + 'tco/virusScanInfo/conf',
        getSetEmailSetting: apiGateWay.rmmTco + 'tco/emailAlertStatus/conf',
        getSetNetworkSetting: apiGateWay.rmmTco + 'tco/networkSettings/conf',
        getSetAuthenticationSetting: apiGateWay.rmmTco + 'tco/authenticationSettings/conf',
        setMFPConnectionSetting: apiGateWay.rmmTco + 'tco/device/connection/set',
        fileUploadBin: apiGateWay.rmmTco + 'tco/cloning/fileUpload',
        getSetOSASetting: apiGateWay.rmmTco + 'tco/osasettings/conf'
    },
    rmmRealTime: {
        signalRConnectUrl: apiGateWay.rmm + 'db/signalr-connect',
        watchUrl: apiGateWay.rmm + 'db/watch',

    },
    rmmDisplayOperation: {
        getDisplayList: apiGateWay.rmmIdp + 'device/getDeviceList',
        getDisplayDetail: apiGateWay.rmmIdp + 'device/getDeviceDetail',
        updateInfo: apiGateWay.rmmIdp + 'device/executeUpdateInformation',
        powerManagement: apiGateWay.rmmIdp + 'device/executeManagePower',
        changePort: apiGateWay.rmmIdp + 'device/executeChangeInputMode',
        deleteDevice: apiGateWay.rmmIdp + 'device/deleteDevice',
        applyDeviceSchedule: apiGateWay.rmmIdp + 'device/applyDeviceSchedule',
        getDeviceSchedule: apiGateWay.rmmIdp + 'device/getDeviceSchedule',
        updateDeviceInfo: apiGateWay.rmmIdp + 'device/updateDeviceInfo',
        checkProgress: apiGateWay.rmmIdp + 'common/checkProgress'
    },
    rmmReportOperations: {
        getReportSettings: apiGateWay.rmm + 'group/report/conf',
        createReport: apiGateWay.rmm + 'group/report/latest/create',
        downloadReport: apiGateWay.rmm + 'group/download',
        emailStatus: apiGateWay.rmm + 'group/email',
        getDeviceReportSchedule: apiGateWay.rmm + 'group/report/schedule',
        setDeviceReportSchedule: apiGateWay.rmm + 'group/report/schedule',
        getReportScheduleDetails: apiGateWay.rmm + 'reports/schedule/details',
        deleteSchedules: apiGateWay.rmm + 'reports/schedule/del',
        setSchedules: apiGateWay.rmm + 'reports/schedule/set'
    },
    rmmOperationLogs: {
        getOperationLogs: apiGateWay.rmm + 'operationLog',
        exportOperationLogs: apiGateWay.rmm + 'export/operationLog',
        deleteOperationLogs: apiGateWay.rmm + 'operationLog/del',
        downloadOperationLogs: apiGateWay.rmm + 'operationLog/download',
    },
    rmmDeviceLogs: {
        getDeviceLogs: apiGateWay.rmm + 'deviceLog',
        deleteDeviceLogs: apiGateWay.rmm + 'deviceLog/del',
        exportDeviceLogs: apiGateWay.rmm + 'export/deviceLog',
        downloadDeviceLogs: apiGateWay.rmm + 'deviceLog/download',
        oldestLogTime: apiGateWay.rmm + 'deviceLog/oldestLogTime',
    },
    rmmCustomDeviceType: {
        getDeviceTypeList: apiGateWay.rmm + 'group/deviceTypeList',
        getAdditionalInfoList: apiGateWay.rmm + 'group/deviceType/addlInfo',
        // deleteAdditionalInfoList: apiGateWay.rmm + 'group/deviceType/addlinfoconf/del',
        createAdditionalInfoCounter: apiGateWay.rmm + 'group/deviceType/addlinfoconf',
        createDeviceTypeSeeting: apiGateWay.rmm + 'group/deviceType/conf',
        setDeviceTypeSetting: apiGateWay.rmm + 'group/deviceType/conf',
        getDeviceTypeSettingDetail: apiGateWay.rmm + 'group/deviceType/conf',
        applyDeviceTypeSettingToMfp: apiGateWay.rmm + 'device/customDeviceType',
        deleteDeviceTypeSetting: apiGateWay.rmm + 'group/deviceType/conf/del',
        deleteAdditionalInfoCounter: apiGateWay.rmm + 'group/deviceType/addlinfoconf/del',
        exportDeviceType: apiGateWay.rmm + 'export/deviceType',
        importDeviceType: apiGateWay.rmm + 'import/deviceType',
        downloadReport: apiGateWay.rmm + 'deviceType/download',
    },
    rmmPrinterDriver: {
        getPrinterDriverList: apiGateWay.rmmPDM + 'file/getDriverFileList',
        deletePrinterDriverFile: apiGateWay.rmmPDM + 'file/deleteDriverFile',
        fetchSupportDeviceList: apiGateWay.rmmPDM + 'file/fetchSupportDeviceList',
        getDriverFileConfig: apiGateWay.rmmPDM + 'file/getDriverFileConfig',
        updateDriverFileInfo: apiGateWay.rmmPDM + 'file/updateDriverFileInfo',
        downloadDriverFile: apiGateWay.rmmPDM + 'file/downloadDriverFile',
        uploadDriverFile: apiGateWay.rmmStateful + 'dd/file/uploadDriverFile',
        updateDriverFileConfig: apiGateWay.rmmPDM + 'file/updateDriverFileConfig',
        editBindingToDevice: apiGateWay.rmmPDM + 'device/editBindingToDevice',
        sendmail: apiGateWay.rmm + 'mail/sendmail',
        getPrinterDriverListBasedOnDevice: apiGateWay.rmmPDM + 'file/getDriverFileList',
        registerDownloadInfo: apiGateWay.rmmPDM + 'file/registerDownloadInfo',
        checkProgress: apiGateWay.rmmPDM + 'common/checkProgress',
        uploadProgress: apiGateWay.rmmStateful + 'dd/file/uploadProgress'
    },
    rmmTechnicalService: {
        getDataList: apiGateWay.rmmFss + 'data/getDataList',
        getDataHistory: apiGateWay.rmmFss + 'data/getDataHistory',
        applyDataSchedule: apiGateWay.rmmFss + 'data/applyDataSchedule',
        setDataSchedule: apiGateWay.rmmFss + 'data/setDataSchedule',
        getDataScheduleItemList: apiGateWay.rmmFss + 'data/getDataScheduleItemList',
        addDataScheduleItem: apiGateWay.rmmFss + 'data/addDataScheduleItem',
        updateDataScheduleItem: apiGateWay.rmmFss + 'data/updateDataScheduleItem',
        deleteDataScheduleItem: apiGateWay.rmmFss + 'data/deleteDataScheduleItem',
        exportDataDetail: apiGateWay.rmmStateful + 'fss/data/exportDataDetail',
        getAlertList: apiGateWay.rmmFss + 'alert/getAlertList',
        exportAlertList: apiGateWay.rmmStateful + 'fss/alert/exportAlertList',
        exportAlertDetail: apiGateWay.rmmStateful + 'fss/alert/exportAlertDetail',
        getFirmwareUpdateList: apiGateWay.rmmFss + 'fwupd/getFirmwareUpdateList',
        getFirmwareDataList: apiGateWay.rmmFss + 'fwupd/getFirmwareDataList',
        deleteFirmwareData: apiGateWay.rmmFss + 'fwupd/deleteFirmwareData',
        setUpdateReservation: apiGateWay.rmmFss + 'fwupd/setUpdateReservation',
        uploadFirmwareData: apiGateWay.rmmStateful + 'fss/fwupd/uploadFirmwareData',
        removeUpdateReservation: apiGateWay.rmmFss + 'fwupd/removeUpdateReservation',
        getInitialUrl: apiGateWay.rmmFss + 'service/getInitialUrl',
        getRegisterDeviceUrl: apiGateWay.rmmFss + 'service/getRegisterDeviceUrl',
        getServiceList: apiGateWay.rmmFss + 'service/getServiceList',
        setService: apiGateWay.rmmFss + 'service/setService',
        getRemoteMaintenanceList: apiGateWay.rmmFss + 'diag/getRemoteDiagList',
        getMaintenanceItemList: apiGateWay.rmmFss + 'diag/getRemoteDiagItemList',
        uploadProgress: apiGateWay.rmmStateful + 'fss/fwupd/uploadProgress',
        getFirmwareUpdateHistory: apiGateWay.rmmFss + 'fwupd/getFirmwareUpdateHistory',
        exportFirmwareUpdateHistory: apiGateWay.rmmStateful + 'fss/fwupd/exportFirmwareUpdateHistory',
        getFirmwareUpdateDetail: apiGateWay.rmmFss + 'fwupd/getFirmwareUpdateDetail',
        setRemoteDiag: apiGateWay.rmmFss + 'diag/setRemoteDiag',
        getRemoteDiagHistory: apiGateWay.rmmFss + 'diag/getRemoteDiagHistory',
        exportRemoteDiagHistory: apiGateWay.rmmStateful + 'fss/diag/exportRemoteDiagHistory',
        applyRemoteDiag: apiGateWay.rmmFss + 'diag/applyRemoteDiag',
        deleteDataRemoteItem: apiGateWay.rmmFss + 'diag/deleteRemoteDiagItem',
        addRemoteDiagItem: apiGateWay.rmmFss + 'diag/addRemoteDiagItem',
        updateRemoteDiagItem: apiGateWay.rmmFss + 'diag/updateRemoteDiagItem',
        downloadExportData: apiGateWay.rmmStateful + 'fss/export/downloadExportData',
        removeDataSchedule: apiGateWay.rmmFss + 'data/removeDataSchedule',
        removeRemoteDiag: apiGateWay.rmmFss + 'diag/removeRemoteDiag',
        getFssSetupItem: apiGateWay.rmmFss + 'service/getFssSetupItem',
        executeFssSetup: apiGateWay.rmmStateful + 'fss/service/executeFssSetup',
        setOTAMode: apiGateWay.rmmStateful + 'fss/fwupd/setOTAMode',
        getFirmwareVersionDetails: apiGateWay.rmmFss + 'fwupd/getFirmwareVersionDetails',
        sendfwupdatemail: apiGateWay.rmmFss + 'fwupd/sendmail',
        getOTAFirmwareUpdateList: apiGateWay.rmmFss + 'fwupd/getFirmwareUpdateOTAList',
        setEUOTAMode: apiGateWay.rmmStateful + 'fss/fwupd/setOTAModeUsingOnlyMFPIF',
    },
    rmmUsageReport: {
        byFunction: apiGateWay.rmm + 'group/usageReport/byFunction',
        dailyOutput: apiGateWay.rmm + 'group/usageReport/dailyOutput',
        getConfig: apiGateWay.rmm + 'group/usageReport/exportConf',
        updateConfig: apiGateWay.rmm + 'group/usageReport/exportConf',
        createReport: apiGateWay.rmm + 'group/usageReport/export',
        usageTarget: apiGateWay.rmm + 'group/usageTarget/conf',

    },
    rmmConnectWiseOperations: {
        setTicketRegistrationConf: apiGateWay.rmm + 'group/setTicketRegistrationConf',
        getTicketRegistrationConf: apiGateWay.rmm + 'group/getTicketRegistrationConf',
        getCompnayListConf: apiGateWay.rmm + 'group/getCompanyOrServiceBoardList',
    },
    rmmCEOJuiceOperations: {
        setCEOJuiceCustomerConf: apiGateWay.rmm + 'group/customer/list',
        getCEOJuiceCustomerConf: apiGateWay.rmm + 'group/customer/list',
        createCEOJuiceCustomer: apiGateWay.rmm + 'group/customer/create'
    },
    rmmTaskOperations: {
        getTaskList: apiGateWay.rmm + 'group/tasks/list',
        getTaskDetails: apiGateWay.rmm + 'group/tasks/details',
        getDuplicateTasks: apiGateWay.rmm + 'group/tasks/ignore',
        deleteTaskDetails: apiGateWay.rmm + 'group/tasks/details/del'
    },
    rmmRemoteWebPageAccess: {
        getEmbeddedWebPageUrl: apiGateWay.rmm + 'device/embeddedWebPageURL',
    }
};

/*
* For easier debugging in development mode, you can import the following file
* to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
*
* This import should be commented out in production mode because it will have a negative impact
* on performance if an error is thrown.
*/
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.


import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../../../../shared/services/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-confirm-operation',
    templateUrl: './confirm-delete.component.html',
    styleUrls: ['./confirm-delete.component.scss']
})
export class ConfirmDeleteComponent implements OnInit {

    // handle event on escape key
    @HostListener('document:keydown.escape', ['$event'])
    onEscapeKeyHandler(event: KeyboardEvent) {
        this.confirmDialogRef.close(3);
    }

    /**
     * @description Title of the ConfirmDeleteComponent dialog.  
     */
    public title: string;
    
    /**
     * @description Message of the ConfirmDeleteComponent dialog.  
     */
    public message: string;

    public alertMode: boolean;

    /**
     * @description If show option is true show other services checkbox so that end user can delete other service.
     */
    public showOption: boolean;

    /**
     * @description isRmmAdmin is true if user is added as admin from Synappx Manage else false.
     */
    public isRmmAdmin: boolean;
    
    /**
     * @description isSynappx is true if user is added as admin from Synappx Go else false.
     */
    public isSynappx: boolean;
    
    /**
     * @description Synappx Go lable for check box.
     */
    public synappxGoLabel: string = 'Synappx Go';

    /**
     * @description Synappx Manage lable for check box.
     */
    public synappxManageLabel: string = 'Synappx Manage';
    /**
     * The list of services, this selected user will be deleted as admin
     * @description This array will contain values like go, rmm, cp
     * 
     * 'cp' is always by default present
     * go and rmm will be deleted based on checkbox state.
     * 
     */
    toDelete: Array<string> = new Array<string>();
    
    /**
     * The other services (go/rmm/scp) where this user can be deleted
     */
    private otherServices: Array<string>;
    
    private tenantData: any;

    /**
     * @description true to show Synappx Go (customer has license for go). false to hide the 'Synappx Go' checkbox.
     */
    private showSynappxGo: boolean;
    
    /**
     * @description true to show Synappx Cloud Print (customer has license for Synappx Cloud Print). false to hide the 'Synappx Cloud Print' checkbox.
     */
    private showSCP: boolean;
    
    /**
     * @description true to show Synappx Manage (customer has license for Synappx Manage). false to hide the 'Synappx Manage' checkbox.
     */
    private showSynappxManage: boolean;
    
    /**
     * @description Synappx Cloud Print lable for check box.
     */
    SynappxCloudPrintLabel = 'Synappx Cloud Print';
    
    constructor(
        public confirmDialogRef: MatDialogRef<ConfirmDeleteComponent>,
        public authService: AuthService,
        public translate: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: any) {
            this.title = this.data.title;
            this.message = this.data.message;
            this.alertMode = this.data.alertMode || false;
            this.isRmmAdmin = this.data.isRmmAdmin;
            this.isSynappx = this.data.isSynappx;
            this.showOption = this.data.showOption;
            this.otherServices = this.data.otherServices;
            this.tenantData = this.authService.tenantInfo;
            this.confirmDialogRef;
            // handle event on click of background
            this.confirmDialogRef.backdropClick().subscribe(() => {
                this.confirmDialogRef.close(2);
            });
        }

    ngOnInit() {
        this.showGo();
        this.showRmm();
        this.showSCP = this.otherServices.includes('scp');
        this.translateCheckBoxLabel();
    }

    /**
     * @description Used to translate check box label.
     */
    private translateCheckBoxLabel() {
        this.synappxGoLabel = this.translate.instant('SynappxGo');
        this.synappxManageLabel = this.translate.instant('CloudRMM');
        this.SynappxCloudPrintLabel = this.translate.instant('SynappxCloudPrint');
    }

    /**
     * @description show synappx go checkbox and add synappx into toDelete array if deleting from synappx go service.
     */
    showGo() {
        if (this.isSynappx) {
            this.showSynappxGo = true;
            this.addProduct('synappx');
        } else {
            this.showSynappxGo = this.otherServices.includes('synappx');
        }
    }

    /**
     * @description show synappx manage checkbox and add rmm into toDelete array if deleting from synappx manage service.
     */
    showRmm() {
        if (this.isRmmAdmin) {
            this.showSynappxManage = true;
            this.addProduct('rmm');
        } else {
            this.showSynappxManage = this.otherServices.includes('rmm');
        }
    }

    /**
     * @description Add the product to the final result
     * @param product The product name to add
     */
    addProduct(product: string) {
        if (!this.toDelete.includes(product)) {
            this.toDelete.push(product);
        }
    }

    /**
     * @description Remove the product name from the final result
     * @param product The product name to remove
     */
    removeProduct(product: string) {
        this.toDelete = this.toDelete.filter(entry => entry != product);
    }

     /**
     * handle the selection change event on the checkboxes in the UI
     * @param event The selection change event for the checkbox from browser
     * @param product The product/service name corresponding to the checkbox
     */
     selectionChange(event, product: string) {
        // add / remove the product name in `toDelete` array
        if ((product == 'synappx' && this.showSynappxGo) ||
            (product == 'scp' && this.showSCP) ||
            (product == 'rmm' && this.showSynappxManage)) {
            if (event) {
                // checked - add go/rmm to result
                this.addProduct(product);
            } else {
                // unchecked - remove go/rmm from the result
                this.removeProduct(product);
            }
        }
    }

    /**
     * Handle the click event of Cancel button
     * @description close this dialog with {action: false, toDelete: null}
     */
    onCancel(): void {
        this.confirmDialogRef.close({action: false, toDelete: null});
    }

    /**
     * Handle the click event of OK button
     * @description close this dialog with {action: true, toDelete: this.toDelete}
     */
    onOk(): void {
        // Close the dialog with the result
        this.confirmDialogRef.close({
            action: true,
            toDelete: this.toDelete
        });
    }
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  display: flex;
  flex-direction: column;
  min-width: 450px;
}

.container > * {
  width: 100%;
}

.form {
  display: flex;
  padding-top: 6px;
}

.mat-form-field {
  font-size: 16px;
  flex-grow: 1;
}

.permission_grant_msg {
  margin: 0 0 16px 0;
}

.mat-dialog-actions {
  margin-bottom: -11px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImdyYW50LXBlcm1pc3Npb24tbG9naW4uY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDSSxhQUFBO0VBQ0Esc0JBQUE7RUFDQSxnQkFBQTtBQUNKOztBQUVBO0VBQ0ksV0FBQTtBQUNKOztBQUVBO0VBQ0ksYUFBQTtFQUNBLGdCQUFBO0FBQ0o7O0FBRUE7RUFDSSxlQUFBO0VBQ0EsWUFBQTtBQUNKOztBQUVBO0VBQ0ksa0JBQUE7QUFDSjs7QUFFQTtFQUNJLG9CQUFBO0FBQ0oiLCJmaWxlIjoiZ3JhbnQtcGVybWlzc2lvbi1sb2dpbi5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jb250YWluZXIge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgICBtaW4td2lkdGg6IDQ1MHB4O1xufVxuXG4uY29udGFpbmVyPioge1xuICAgIHdpZHRoOiAxMDAlO1xufVxuXG4uZm9ybSB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBwYWRkaW5nLXRvcDogNnB4O1xufVxuXG4ubWF0LWZvcm0tZmllbGQge1xuICAgIGZvbnQtc2l6ZTogMTZweDtcbiAgICBmbGV4LWdyb3c6IDE7XG59XG5cbi5wZXJtaXNzaW9uX2dyYW50X21zZyB7XG4gICAgbWFyZ2luOiAwIDAgMTZweCAwO1xufVxuXG4ubWF0LWRpYWxvZy1hY3Rpb25zIHtcbiAgICBtYXJnaW4tYm90dG9tOiAtMTFweDtcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/layout/components/dialogs/grant-permission-login/grant-permission-login.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,WAAA;AACJ;;AAEA;EACI,aAAA;EACA,gBAAA;AACJ;;AAEA;EACI,eAAA;EACA,YAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,oBAAA;AACJ;AACA,48BAA48B","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    min-width: 450px;\n}\n\n.container>* {\n    width: 100%;\n}\n\n.form {\n    display: flex;\n    padding-top: 6px;\n}\n\n.mat-form-field {\n    font-size: 16px;\n    flex-grow: 1;\n}\n\n.permission_grant_msg {\n    margin: 0 0 16px 0;\n}\n\n.mat-dialog-actions {\n    margin-bottom: -11px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
